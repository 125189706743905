import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ImageMap from "./ImageMap";
import Contacto from "../src/Contacto/Contacto";
import Media from "../src/Media/Media";
import Nosotros from "../src/Nosotros/Nosotros";
import Tienda from "../src/Shop/Tienda";
import Tickets from "../src/Tickets/Tickets";
import Carnets from "./Carnets/Carnets";
//import ProductoDetalle from "./Shop/ProductoDetalle";
//import Carrito from "./Shop/Carrito";
import NotFound from "./404/NotFound";
import AvisoLegal from "./Politicas/AvisoLegal";
import PoliticaPrivacidad from "./Politicas/PoliticaPrivacidad";
import PoliticaCookies from "./Politicas/PoliticaCookies";
import TerminosCondiciones from "./Politicas/TerminosCondiciones";
import PoliticaDevolucionesEnvio from "./Politicas/PoliticaDevolucionesEnvio";
import ScrollToTop from "./Footer/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<ImageMap />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/media" element={<Media />} />
        <Route path="/tienda" element={<Tienda />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/carnets" element={<Carnets />} />
        { /* <Route path="/producto/:id" element={<ProductoDetalle />} />  */}
        { /*  <Route path="/carrito" element={<Carrito />} />  */}
        <Route path="*" element={< NotFound />} />
        <Route path="/aviso-legal" element={< AvisoLegal />} />
        <Route path="/politica-de-privacidad" element={< PoliticaPrivacidad />} />
        <Route path="/politica-de-cookies" element={< PoliticaCookies />} />
        <Route path="/terminos-y-condiciones" element={< TerminosCondiciones />} />
        <Route path="/devoluciones" element={< PoliticaDevolucionesEnvio />} />

        {/* Redirecciones */}
        <Route path="/producto/:id" element={<Navigate to="/tienda" />} />
        <Route path="/carrito" element={<Navigate to="/tienda" />} />
        <Route path="/nosotros" element={<Navigate to="/carnets" />} />
      </Routes>
    </Router>
  );
}

export default App;
