import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import imagen1 from './galeria/1.jpg';
import imagen2 from './galeria/002.jpg';
import imagen3 from './galeria/0003.jpg';
import imagen4 from './galeria/0004.jpg';
import imagen5 from './galeria/0006.jpg';
import '../fonts/fonts.css';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

// Define an array of layout objects for dynamic positioning and sizing
const collageLayout = [
  { x: 0, y: 0, width: '50%', height: '50%' }, // Top-left image
  { x: '50%', y: 0, width: '30%', height: '30%' }, // Top-right image (smaller)
  { x: 0, y: '50%', width: '40%', height: '30%' }, // Bottom-left image
  { x: '40%', y: '50%', width: '60%', height: '50%' }, // Bottom-right image (larger)
  { x: '70%', y: '70%', width: '30%', height: '30%' }, // New layout for the fifth image
];

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // Responsive layout with minimum width
  grid-gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); // Adjust minimum width for smaller screens
  }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  grid-column-start: ${({ x }) => x + 1};
  grid-row-start: ${({ y }) => y + 1};
  grid-column-end: ${({ x, width }) => parseInt(x) + parseInt(width.replace('%', '')) / 100 + 1};
  grid-row-end: ${({ y, height }) => parseInt(y) + parseInt(height.replace('%', '')) / 100 + 1};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover div {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 35, 111, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Anton', sans-serif;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const images = [
  { src: imagen1, link: 'https://drive.google.com/drive/folders/1wyZeRI6GmvPeUFT8qJgw-6BHHR3UAE7S?usp=sharing', text: '05-04-2024' },
  { src: imagen2, link: 'https://drive.google.com/drive/folders/1UlPyz5Ehl3iWDlrH24HTOALGFNC3do0Q?usp=sharing', text: '08-03-2024' },
  { src: imagen3, link: 'https://drive.google.com/drive/folders/1vnO0QDO0OiVUoHGHAyTIAjMEzvw9m2Y4?usp=sharing', text: '08-11-2023' },
  { src: imagen4, link: 'https://drive.google.com/drive/folders/1IezZR8pdyHcYoFz1EQvh3UeSGIK42V9C?usp=sharing', text: '15-12-2023' },
  { src: imagen5, link: 'https://drive.google.com/drive/folders/1PeJqUMjPKF0oVenZVp21rvWI1tNNopOI?usp=drive_link', text: '15-12-2023' },

];

function Media() {
  return (
    <PageContainer>
      <Navbar />
      <Content style={{ marginTop: '1rem' }}>
        <h1 style={{
          display: 'flex',
          justifyContent: 'center',
          fontFamily: 'Font',
          color: '#575756',
          fontSize: '20px',
          marginBottom: '1rem'
        }}>
          MEDIA
        </h1>
        <StyledSeparator />
        <Grid>
          {images.map((image, index) => (
            <a key={index} href={image.link} style={{ textDecoration: 'none' }}>
              <GridItem
                x={collageLayout[index].x}
                y={collageLayout[index].y}
                width={collageLayout[index].width}
                height={collageLayout[index].height}
              >
                <img src={image.src} alt={`Imagen ${index + 1}`} />
                <Overlay>{image.text}</Overlay>
              </GridItem>
            </a>
          ))}
        </Grid>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default Media;