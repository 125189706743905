import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #050556;
  font-weight: bold;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
`;

const CenteredText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #050556;
  height: 100%;
  text-align: center;
  margin-top: 5rem;
  font-family: "Font";
`;


const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

function Tienda() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Mostrar el popup una vez por sesión
  useEffect(() => {
    const hasSeenPopup = sessionStorage.getItem('hasSeenPopup');
    if (!hasSeenPopup) {
      setTimeout(() => setPopupVisible(true), 2000); // Muestra el popup después de 2 segundos
      sessionStorage.setItem('hasSeenPopup', 'true');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!acceptedTerms) {
      setErrorMessage('Debe aceptar los términos y condiciones.');
      return;
    }

    if (!email) {
      setErrorMessage('Debe ingresar un correo electrónico válido.');
      return;
    }

    try {
      const response = await fetch('https://backaleron.aleronclub.com/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setPopupVisible(false);
        toast.success('Suscripción exitosa', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        const data = await response.json();
        setErrorMessage('Hubo un problema al suscribirse. Intente de nuevo.');
        console.error(data);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Hubo un problema al conectarse con el servidor.');
    }
  };

  return (
    <PageContainer>
      <Navbar />
      <Content style={{ marginTop: "1rem" }}>
        <h1 style={{
          display: "flex",
          justifyContent: "center",
          fontFamily: "Font",
          color: "#575756",
          fontSize: "20px",
          marginBottom: "1rem"
        }}>
          TIENDA
        </h1>
        <StyledSeparator />
<CenteredText>
  Próximamente
</CenteredText>
      </Content>
      <Footer />

      {/* Popup */}
      <PopupOverlay show={popupVisible}>
        <PopupContent>
          <CloseButton onClick={() => setPopupVisible(false)}>×</CloseButton>
          <h2 style={{ color: "#050556", fontFamily: "Font" }}>¿Quieres enterarte?</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Ingresa tu correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: '90%',
                padding: '10px',
                margin: '10px 0',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: "Font",
              }}
            />
            <div style={{ textAlign: 'left', marginBottom: '10px' }}>
              <input
                type="checkbox"
                id="terms"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
              <label htmlFor="terms" style={{ fontFamily: "Font", marginLeft: '5px' }}>
                Acepto los términos y condiciones
              </label>
            </div>
            {errorMessage && <p style={{ color: 'red', fontFamily: "Font" }}>{errorMessage}</p>}
            <button
              type="submit"
              style={{
                backgroundColor: '#050556',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '100%',
                fontFamily: "Font",
              }}
            >
              Suscribirme
            </button>
          </form>
        </PopupContent>
      </PopupOverlay>
      <ToastContainer />
    </PageContainer>
  );
}

export default Tienda;
